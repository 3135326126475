import $ from "jquery";
import get_length_and_segment from "@/helpers/character_counter";
import store from "@/state/store.js";
import Storage from "@/services/storage.js";
import ASCIIFolder from "fold-to-ascii";
import allCountries from "@/helpers/allCountriesCode.json";
import selectRoute from "./global/selectRoute.vue";
export default {
  name: "create-message-template",
  components: {
    selectRoute
  },
  data() {
    return {
      messages: "",
      allCountries: allCountries,
      createSenderID: false,
      displayType: "template",
      success: "",
      errors: "",
      modalType: "",
      messageTemplates: [],
      loadingTemplate: false,
      loading: false,
      type: "alert-danger",
      showSenderID: false,
      message_category: [{
        id: 1,
        name: "SMS"
      }, {
        id: 3,
        name: this.$t("dashboard.send_message.message_templates.form.message.birthday")
      }, {
        id: 4,
        name: this.$t("dashboard.send_message.message_templates.form.message.international")
      }],
      rules: {
        type: [{
          required: true,
          message: this.$t("dashboard.send_message.message_templates.form.message.select_category"),
          trigger: "change"
        }],
        title: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        body: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          max: 459,
          message: this.$t("dashboard.send_message.message_templates.form.message.reduce_chars"),
          trigger: ["change", "blur"]
        }],
        sender_id: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        sender: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }, {
          max: 11,
          message: this.$t("dashboard.sender_id.min"),
          trigger: ["change", "blur"]
        }],
        purpose: [{
          required: true,
          message: this.$t("misc.form.required"),
          trigger: "change"
        }],
        selected_countries: [{
          required: true,
          message: this.$t("dashboard.send_message.message_templates.form.message.select_at_least_one_country"),
          trigger: "change"
        }]
      },
      formData: {
        type: "",
        title: "",
        body: "",
        id: "",
        sender_id: "",
        perMessage: 0,
        purpose: "",
        sender: "",
        selected_countries: [],
        route_name: ""
      },
      sendMessage: {},
      sendMessageRules: {},
      selectedKey: "",
      isEdit: false,
      openSenderIDError: "",
      encodingType: "",
      useGSMOnly: true,
      showGSM_Only_Info: false
    };
  },
  mounted() {
    let editedData = store.getters["uistate/contactForm"];
    const getFormData = store.getters["modal/getFormData"];
    this.formData = {
      type: this.$route.name == "international messages" ? 4 : getFormData.type,
      title: getFormData.title,
      body: getFormData.body,
      id: getFormData.id,
      sender_id: ""
    };
    if (editedData.isEdit) {
      this.selectedKey = editedData.editData.selectedKey;
      this.isEdit = true;
      this.formData.sender_id = this.formData.type == 4 ? editedData.editData.selectedMessage.sender_id.id : "";
    } else {
      this.isEdit = false;
    }
  },
  computed: {
    corporate() {
      const storage = new Storage();
      return storage.getSavedState("isCorporate");
    },
    findNumberOfRecipient() {
      let {
        body
      } = this.formData;
      if (body.length == 0) {
        return 0;
      }
      let {
        messages,
        encoding
      } = get_length_and_segment(body);
      this.formData.perMessage = messages;
      this.formData.encoding = encoding;
      this.encodingType = encoding;
      return messages;
    },
    senderIDs() {
      return store.getters["senderid/allSenderIDs"];
    },
    messageForm() {
      return store.getters["uistate/contactForm"];
    },
    groups() {
      return store.getters["groups/all_groups"];
    },
    templates() {
      return store.getters["campaigns/templates"];
    },
    getGoBackDirectly() {
      let editedData = store.getters["uistate/contactForm"];
      return editedData.goBackDirectly;
    },
    getEncoding() {
      return this.encodingType;
    },
    formatASCII: {
      get() {
        return this.formData.body;
      },
      set(value) {
        if (this.useGSMOnly) {
          this.formData.body = ASCIIFolder.foldReplacing(value);
        } else {
          this.formData.body = value;
        }
      }
    },
    dynamicModalWidth() {
      if (screen.width < 768) {
        return "98%";
      } else {
        return "50%";
      }
    },
    selectedCountries() {
      return this.allCountries;
    }
  },
  methods: {
    handleInputChange(value, key) {
      store.dispatch("modal/updateSingleField", {
        key,
        value
      });
    },
    handleCharacterEncoding(e) {
      // let { body } = this.formData
      // if( body.length == 0) {
      //     return 0
      // }
      // let {
      //     messages,
      // } = get_length_and_segment(body)
      // this.formData.perMessage = messages
      // return messages
    },
    createNewSenderID() {
      this.openSenderIDError = "";
      this.loading = true;
      let payload = {
        sender_id: this.formData.sender,
        purpose: this.formData.purpose
      };
      store.dispatch("senderid/registerSenderID", payload).then(response => {
        const sender = response.data.data;
        if (sender.status == 1) {
          this.formData.sender_id = sender.id;
        }
        if (response) {
          this.loading = false;
          this.createSenderID = false;
        }
      }).catch(err => {
        var _err$response;
        this.loading = false;
        if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.data) == undefined) {
          this.openSenderIDError = this.$t("misc.network_error");
        }
        if (err.response && err.response.status === 404) {
          if (err.response.data.data.sender_id) {
            return this.openSenderIDError = err.response.data.data.sender_id[0];
          }
          this.openSenderIDError = err.response.data.data.error;
        }
      });
    },
    cancelSenderID() {
      this.showSenderID = false;
    },
    submit(formName, type) {
      this.loading = true;
      this.$refs[formName].validate((valid, invalidFields) => {
        if (valid) {
          if (type == "createSenderID") {
            return this.createNewSenderID();
          }
          if (type === "sendAndSave") {
            return this.saveAndSend();
          } else if (type === "save") {
            return this.save();
          } else {
            return this.edit();
          }
        } else {
          this.loading = false;
          // Get specific field errors
          // loop over invalidFields object
          for (const key in invalidFields) {
            // get the first error message
            const message = invalidFields[key][0].message;
            // log the field name and the error message
            setTimeout(() => {
              this.$notify.error({
                title: this.$t("misc.toast.error"),
                message: message,
                position: "top-right",
                duration: 5000
              });
            }, 5);
          }
          return false;
        }
      });
    },
    save() {
      if (this.formData.selected_countries) {
        this.formData.selected_countries = this.formData.selected_countries.join(",");
      } else {
        this.formData.selected_countries = "";
      }
      store.dispatch("campaigns/saveTemplate", this.formData).then(res => {
        if (res) {
          if (res) {
            this.loading = false;
            this.$emit("saveTemplate", res.data);
          }
        }
      }).catch(err => {
        var _err$response2;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response2 = err.response) === null || _err$response2 === void 0 ? void 0 : _err$response2.data) == undefined) {
          this.errors = this.$t("misc.network_error");
        }
      });
    },
    edit() {
      store.dispatch("campaigns/editTemplate", {
        body: this.formData,
        key: this.selectedKey
      }).then(res => {
        if (res) {
          this.loading = false;
          let payload = {
            data: res.data.data,
            key: this.selectedKey,
            message: res.data.message
          };
          this.$emit("editTemplate", payload);
        }
      }).catch(err => {
        var _err$response3;
        this.loading = false;
        if ((err === null || err === void 0 || (_err$response3 = err.response) === null || _err$response3 === void 0 ? void 0 : _err$response3.data) == undefined) {
          this.errors = this.$t("misc.network_error");
        } else {
          this.$notify({
            title: this.$t("misc.toast.error"),
            message: err.response.data.message,
            type: "error"
          });
        }
      });
    },
    insertText(data) {
      // insert at cursor position
      var textarea = document.getElementById("textarea");
      var strPos = 0;
      var br = textarea.selectionStart || textarea.selectionStart == "0" ? "ff" : document.selection ? "ie" : false;
      if (br == "ie") {
        textarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -textarea.value.length);
        strPos = range.text.length;
      } else if (br == "ff") strPos = textarea.selectionStart;
      var front = textarea.value.substring(0, strPos);
      var back = textarea.value.substring(strPos, textarea.value.length);
      this.formData.body = front + data + back;
      strPos = strPos + data.length;
      if (br == "ie") {
        textarea.focus();
        var range = document.selection.createRange();
        range.moveStart("character", -textarea.value.length);
        range.moveStart("character", strPos);
        range.moveEnd("character", 0);
        range.select();
      } else if (br == "ff") {
        textarea.selectionStart = strPos;
        textarea.selectionEnd = strPos;
        textarea.focus();
      }
      this.$refs.message.focus();
    },
    back(type) {
      this.showSenderID = false;
      this.$emit("closeModal", type);
    },
    saveAndSend() {
      let payload = {
        campaign_name: this.formData.title,
        message: this.formData.body,
        sender_id: this.formData.sender_id,
        perMessage: this.formData.perMessage
      };
      this.$emit("goToSelectUsers", payload);
    }
  }
};